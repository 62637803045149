import React from 'react'
import MyDatePicker from '../../MyDatePicker'

const SelectFilterDate = ({dateFilter}) => {
  return (
    <div style={{display:'flex', alignItems:'center', marginLeft:'25px'}}>
        <MyDatePicker dateFilter={dateFilter} type="start"/>
        <div style={{width:'15px',height:'1.5px',backgroundColor:'black', margin: '0px 8px'}}></div>
        <MyDatePicker dateFilter={dateFilter} type="end"/>
    </div>
  )
}

export default SelectFilterDate