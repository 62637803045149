import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDataFromJsonList } from "../../functions/getDataFromJsonList";
import { config } from "../../config";
import Table from "../Table/Table";
import MailingAction from "./Mailings/MailingAction";
import ModalDelete from "./PromotionalLinks/ModalDelete";
import ModalCreateEdit from "./PromotionalLinks/ModalCreateEdit";
import Button from "@mui/material/Button";
import PromotionalLinksAction from "./PromotionalLinks/PromotionalLinksAction";
import getCookie from "../../functions/getCookie";
import { useHistory } from "react-router-dom";
import WrapperAdaptive from "../WrapperAdaptive";


const categoriesBlockStyle = {
    marginLeft: '1%',
    display: 'flex',
    backgroundColor: 'white',
    width: '98%',
}

const addCategoryButton = {
    color: 'white',
    backgroundColor: 'rgb(45, 3, 59)',
    ':hover': {
        backgroundColor: 'rgba(45,3,59,0.75)',
    },
    textTransform: 'none',
    fontSize: 20
}


export default function PromotionalLinks() {
    const [adsLinks, setAdsLinks] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false)
    const [id, setId] = useState(0)
    const [flag, setFlag] = useState(0)
    const [name, setName] = useState('')
    const [deleteOpen, setDeleteOpen] = useState(false)
    const history = useHistory();
    const [sorts, setSorts] = useState([
        { title: null, type: null }
    ])

    console.log(adsLinks);

    const tableSetting = [
        [
            {
                'title': 'Название',
                'width': '30%',
                'data': getDataFromJsonList('name', adsLinks),
                'styled': false,
            },
            {
                'title': 'Код ссылки',
                'width': '40%',
                'data': getDataFromJsonList('link', adsLinks),
                'styled': false,
            },
            {
                'title': 'Дата создания',
                'width': '20%',
                'data': getDataFromJsonList('datetime', adsLinks),
                'styled': false,
            },
            {
                'title': 'Колл-во',
                'width': '20%',
                'data': getDataFromJsonList('cliks', adsLinks),
                'styled': false,
            },
            {
                'title': 'Cумма',
                'width': '20%',
                'data': getDataFromJsonList('replenishments', adsLinks),
                'styled': false,
            },
            {
                'title': 'Действия',
                'width': '10%',
                'data': PromotionalLinksAction(adsLinks, setId, setName, setDeleteOpen),
                'styled': true,
            }
        ],
        {
            'page': {
                'get': page,
                'set': setPage
            },
            'limit': {
                'get': limit,
                'set': setLimit
            },
            'pages': pages,
            'search': {
                'get': search,
                'set': setSearch
            },
            'filter': {
                'ifFiltered': false
            },
            'sort': {
                'get': sorts,
                'set': setSorts
            }
        }
    ]

    useEffect(() => {
        const sort = sorts.find((sortItem) => sortItem.type != null)

        fetch(`${config.host}/lilshop/adsLinks/getSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'limit': limit,
                'page': page,
                'search': search,
                'sort': sort ? sort.title : null,
                'sort_type': sort ? sort.type : null
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setAdsLinks(data.data)
                    if (pages !== data.pages) {
                        setPages(data.pages)
                    }
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/promotionallinks" })
            }
        })

    }, [limit, page, search, flag, sorts])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [pages])

    return <Stack direction={'row'}>
        <Menu active={'Promotional links'} />
        <WrapperAdaptive>
        <Stack direction={'column'} width={'100%'}>
            <Header />
            <ModalDelete open={deleteOpen} setOpen={setDeleteOpen} name={name} id={id} flag={flag} setFlag={setFlag} />
            <ModalCreateEdit open={open} setOpen={setOpen} id={id} flag={flag} setFlag={setFlag} />
            <Stack className="main-style-page">
                <Stack className="inner-in-main-page">
                    <div className="upper-panel-in-inner">
                        <div className="about-page">Рекламные ссылки</div>
                        <button className="btn-add" onClick={() => {
                            setId(0)
                            setOpen(true);
                        }}>Добавить ссылку</button>
                    </div>
                    <div className="split"></div>
                    <Table setting={tableSetting} />
                </Stack>
            </Stack>
        </Stack>
        </WrapperAdaptive>

    </Stack>
}
