import { Stack } from "@mui/material";
import TableCell from "./TableCell";

export default function TableLine({ data, num }) {
    return <Stack direction={'row'}>
        {data.map((item, i) => (
            <TableCell key={i} data={item['data'][num]} num={num} styled={item.styled} width={item.width} one={i === 0} />
        )
        )}
    </Stack>
}
