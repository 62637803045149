import {Alert, Checkbox, FormControlLabel, Snackbar, Stack, TextField} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import {config, quillModules} from "../../../config";
import ReactQuill from "react-quill";
import {useEffect, useState} from "react";
import MarkdownEditor from "@uiw/react-markdown-editor";
import getCookie from "../../../functions/getCookie";
import {useHistory} from "react-router-dom";

const quillStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '40px',
}

const imageStyle = {
    maxWidth: '60%',
    maxHeight: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
}

const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginTop: '10px',
    borderRadius: '20px'
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor:'#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    width: '60%',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}

export default function MessagesCard({title, message}) {
    const [name, setName] = useState('')
    const [text, setText] = useState('')
    const [image, setImage] = useState('')
    const [active, setActive] = useState(true)
    const [open, setOpen] = useState(false)
    const history = useHistory();

    useEffect(() => {
        setName(message.name)
        setText(message.text)
        setImage(message.image)
        setActive(message.active)
    }, [message])
    const saveHandler = () => {
        fetch(`${config.host}/lilshop/messages/updateOne`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'name': name,
                'text': text,
                'image': image,
                'active': active,
            })
        }).then((response) => {
            if (response.status === 200) {
                setOpen(true)
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/constructor"})
            }
        })
    }
    return <Stack sx={{backgroundColor:'white'}} width={'30%'} minWidth={'350px'} marginBottom={'20px'} minHeight={'500px'} height={'30%'}>
        <Stack alignItems={'center'}>
            <Stack fontSize={20} style={{marginBottom:'10px'}}>
                {title}
            </Stack>
            <ReactQuill
                style={quillStyle}
                modules={quillModules}
                theme="snow"
                value={text}
                onChange={setText}/>
            {/*<MarkdownEditor style={quillStyle}*/}
            {/*    value={text}*/}
            {/*    onChange={setText}*/}
            {/*/>*/}
            {/*<TextField multiline  rows={10} value={text} sx={textFieldStyle} onChange={(e)=>setText(e.target.value)}/>*/}
            <Stack minWidth={'300px'} width={'60%'} marginTop={'10px'} marginBottom={'10px'}>
                @first_name - имя пользователя
            </Stack>
            <TextField size="small" value={image} sx={textFieldStyle} label={'Изображение'}
                       onChange={(e) => setImage(e.target.value)}/>
            <img style={imageStyle} src={image}/>
            <Stack>
                <FormControlLabel sx={textFieldStyle} control={<Checkbox  checked={active} onChange={()=>setActive(!active)}/>} label="Включить" />
            </Stack>
            <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
                <Alert onClose={()=>setOpen(false)} severity="success">
                    Сохранено!
                </Alert>
            </Snackbar>
            <Button sx={saveButtonStyle} onClick={saveHandler}>Сохранить</Button>
        </Stack>
    </Stack>
}
