import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import "./App.css";
import Analytics from "./component/pages/Analytics";
import Scrollbars from "react-custom-scrollbars-2";
import { useRef } from "react";
import Categories from "./component/pages/Categories";
import Goods from "./component/pages/Goods";
import Orders from "./component/pages/Orders";
import Users from "./component/pages/Users";
import Mailing from "./component/pages/Mailing";
import PromotionalLinks from "./component/pages/PromotionalLinks";
import Constructor from "./component/pages/Constructor";
import Payments from "./component/pages/Payments";
import Authentication from "./component/pages/Authentication";
import Refills from "./component/pages/Refills";
import Sells from "./component/pages/Sells";

function App() {
    const windowHeight = useRef(window.innerHeight);
    return (
        <div className="App">
            <Scrollbars
                autoHeightMin={windowHeight.current}
                autoHeight={true}
                autoHide={true}
            >
                <Router>
                    <Switch>
                        <Route
                            path="/lilshop/Refills"
                            component={Refills}
                            exact
                        />
                        <Route
                            path="/lilshop/analytics"
                            component={Analytics}
                            exact
                        />
                        <Route path="/lilshop/sells" component={Sells} exact />
                        <Route
                            path="/lilshop/categories"
                            component={Categories}
                            exact
                        />
                        <Route path="/lilshop/goods" component={Goods} exact />
                        <Route
                            path="/lilshop/orders"
                            component={Orders}
                            exact
                        />
                        <Route path="/lilshop/users" component={Users} exact />
                        <Route
                            path="/lilshop/mailing"
                            component={Mailing}
                            exact
                        />
                        <Route
                            path="/lilshop/promotionallinks"
                            component={PromotionalLinks}
                            exact
                        />
                        <Route
                            path="/lilshop/constructor"
                            component={Constructor}
                            exact
                        />
                        <Route
                            path="/lilshop/payments"
                            component={Payments}
                            exact
                        />
                        <Route
                            path="/lilshop/authentication"
                            component={Authentication}
                            exact
                        />
                        <Redirect
                            from={"/lilshop/"}
                            to="/lilshop/analytics"
                            exact
                        />
                        <Redirect from={"/"} to="/lilshop" exact />
                        <Redirect from={"*"} to="/notfound" />
                    </Switch>
                </Router>
            </Scrollbars>
        </div>
    );
}

export default App;
