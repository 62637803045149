import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import Button from '@mui/material/Button';
import React, { useEffect, useLayoutEffect, useState } from "react";
import { config } from "../../config";
import Table from "../Table/Table";
import ModalCreateEdit from "./Categories/ModalCreateEdit";
import ModalDelete from "./Categories/ModalDelete";
import { getDataFromJsonList } from "../../functions/getDataFromJsonList";
import CategoryAction from "./Categories/CategoryAction";
import CategoryFilter from "./Categories/CategoryFilter";
import { useHistory } from "react-router-dom";
import getCookie from "../../functions/getCookie";
import { useStoreContext } from "../../store/store";
import WrapperAdaptive from "../WrapperAdaptive";




const parentNameHandler = (name) => {
    if (name) {
        return name
    } else return '--'
}

const visionHandler = (active) => {
    if (active) {
        return 'Видна'
    }
    return 'Скрыта'
}

export default function Categories() {
    const {state,dispatch} = useStoreContext();

    const [categories, setCategories] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [parents, setParents] = useState([])
    const [parent, setParent] = useState(-1)
    const [open, setOpen] = useState(false)
    const [id, setId] = useState(0)
    const [flag, setFlag] = useState(0)
    const [name, setName] = useState('')
    const [deleteOpen, setDeleteOpen] = useState(false)
    const history = useHistory();
    const [sorts, setSorts] = useState([
        { title: 'Название', name: "name", type: null },
        { title: 'Родительская категория', name: "parent_id", type: null },
        { title: 'Колл-во товаров', name: "goods_count", type: null },
        { title: 'Колл-во материалов', name: "active", type: null }
    ])

    const tableSetting = [
        [
            {
                'title': 'Название',
                'width': '20%',
                'data': getDataFromJsonList('name', categories),
                'styled': false,
                'id': getDataFromJsonList('id', categories),
                'position': getDataFromJsonList('position', categories)
            },
            {
                'title': 'Родительская категория',
                'width': '30%',
                'data': getDataFromJsonList('parent_name', categories, parentNameHandler),
                'styled': false,
            },
            {
                'title': 'Колл-во товаров',
                'width': '20%',
                'data': getDataFromJsonList('goods_count', categories),
                'styled': false,
            },
            {
                'title': 'Видимость',
                'width': '20%',
                'data': getDataFromJsonList('active', categories, visionHandler),
                'styled': false,
            },
            {
                'title': 'Действия',
                'width': '10%',
                'data': CategoryAction(categories, flag, setFlag, setId, setOpen, setName, setDeleteOpen),
                'styled': true,
            },

        ],
        {
            'page': {
                'get': page,
                'set': setPage
            },
            'limit': {
                'get': limit,
                'set': setLimit
            },
            'pages': pages,
            'search': {
                'get': search,
                'set': setSearch
            },
            'filter': {
                'ifFiltered': true,
                'get': <CategoryFilter parents={parents} parent={parent} setParent={setParent} />
            },
            'sort': {
                'get': sorts,
                'set': setSorts
            }
        }
    ]

    useLayoutEffect(() => {
        fetch(`${config.host}/lilshop/categories/getParentsList`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setParents(data)
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/categories" })
            }
        })

    }, [])

    useEffect(() => {
        const sort = sorts.find((sortItem) => sortItem.type != null)

        fetch(`${config.host}/lilshop/categories/getSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'limit': limit,
                'page': page,
                'search': search,
                'parent_id': parent,
                'sort': sort ? sort.name : 'position',
                'sort_type': sort ? sort.type : 'asc'
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setCategories(data.data)
                    if (pages !== data.pages) {
                        setPages(data.pages)
                    }
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/categories" })
            }
        })

    }, [limit, page, search, parent, flag, sorts])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [pages])

    return <Stack direction={'row'}>
        <Menu active={'Categories'} />
        <WrapperAdaptive>
            <Stack direction={'column'} style={{width:'100%'}}>
                <Header />
                <ModalDelete open={deleteOpen} setOpen={setDeleteOpen} name={name} id={id} flag={flag} setFlag={setFlag} />
                <ModalCreateEdit open={open} setOpen={setOpen} id={id} flag={flag} setFlag={setFlag} />
                <Stack className="main-style-page">
                    <Stack className="inner-in-main-page ">
                        <div className="upper-panel-in-inner">
                            <div className="about-page">Категории</div>
                            <button className="btn-add" onClick={() => {
                                setId(0)
                                setOpen(true);
                            }}>Добавить категорию</button>
                        </div>
                        <div className="split"></div>
                        <Table setting={tableSetting} section={'Categories'} />
                    </Stack>
                </Stack>
            </Stack>
        </WrapperAdaptive>
            </Stack>

}
