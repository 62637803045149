import {Stack} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


const iconStyle = {
    '&:hover': {
        color: 'rgb(107,105,105)',
    },
}

export default function MailingAction(mailings, setId, setName, setOpen, setDeleteOpen){
    return mailings.map((item, i) => (
            <Stack key={i}
                   direction={"row"} justifyContent={'space-evenly'}>
                <Stack onClick={() => {
                    setId(item.id)
                    setOpen(true);
                }}><EditIcon sx={iconStyle} /></Stack>
                <Stack><DeleteIcon sx={iconStyle} onClick={()=>{
                    setName(item.name)
                    setId(item.id)
                    setDeleteOpen(true)}} /></Stack>
            </Stack>
        ))
}
