import { Stack } from "@mui/material";
import { useEffect } from "react";
import ImportExportIcon from '@mui/icons-material/ImportExport';



export default function TableCell({ data, styled, width, num, one, sort_item, goSort }) {
    const cellStyle = {
        width: width,
        minWidth: 150,
        display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        margin: "10px 0px",
        color: '#3a3b45'
    }



    if (sort_item) {
        return <Stack style={cellStyle}>
            <i onClick={() => goSort(data)}><ImportExportIcon /></i> {data}
        </Stack >
    } else {
        return <Stack style={cellStyle} >
            {data}
        </Stack >
    }

}
//</></Stack>