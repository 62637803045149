import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {MenuItem, Select, Stack, TextField} from "@mui/material";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import {config, quillModules} from "../../../config";
import {useHistory} from "react-router-dom";
import getCookie from "../../../functions/getCookie";

Quill.register('modules/imageResize', ImageResize)

const modalStyle = {
    position: 'absolute',
    top: '20%',
    left: '20%',
    right: '20%',
    width: '60%',
    borderRadius: 1,
    backgroundColor: 'white',
    overflow: 'scroll',
    maxHeight: '70%',
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: 'rgb(45, 3, 59)',
    ':hover': {
        backgroundColor: 'rgba(45,3,59,0.75)',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 50,
    width: '30%',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}

export default function ModalDelete({open, setOpen, id, name, flag, setFlag, setParentOpen}) {
    const history = useHistory();

    const handleAccept = () => {
        fetch(`${config.host}/lilshop/buttons/deleteOne`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'id': id
            })
        }).then((response) => {
            if (response.status === 200) {
                setOpen(false)
                setParentOpen(false)
                setFlag(flag + 1)
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/constructor"})
            }
        })
    }


    return <div>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Stack
                    style={{marginTop: 10, fontSize: 25, marginBottom: 20}} alignItems={'center'}>Удалить кнопку {name}?</Stack>
                <Stack flexDirection={'row'} justifyContent={'center'}>
                    <Button sx={saveButtonStyle} onClick={handleAccept}>Да</Button>
                    <Button sx={saveButtonStyle} onClick={()=>setOpen(false)}>Нет</Button>
                </Stack>
            </Box>
        </Modal>
    </div>
}
