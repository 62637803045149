import {Stack} from "@mui/material";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LockIcon from "@mui/icons-material/Lock";


const iconStyle = {
    '&:hover': {
        color: 'rgb(107,105,105)',
    },
}

export default function ReffilsAction(refills, setBanId ,setId, setName, setOpen){
    return refills.map((item, i) => (
        <Stack style={{height: 30}} key={i}
        direction={"row"} justifyContent={'space-evenly'}>
            <Stack>{item.is_ban ? <LockIcon sx={iconStyle} onClick={() => {
                setBanId(item.user)
            }}/> : <LockOpenIcon sx={iconStyle} onClick={() => {
                setBanId(item.user)
            }}/>}</Stack>
            <Stack><AccountBalanceWalletIcon sx={iconStyle} onClick={() => {
                setOpen(true)
                setName(item.username)
                setId(item.user)
            }}/></Stack>
        </Stack>
        ))
}

