import {Stack} from "@mui/material";
import * as React from "react";

const activeButtonStyle = {
    fontSize:25,
    color:'rgb(0,94,190)',
    ':hover': {
        color: 'rgb(107,105,105)',
    },
    marginLeft:'10px',
    marginRight: '20px',
    // borderBottom: 'solid 1px rgb(0,94,190)'
}

const inactiveButtonStyle = {
    fontSize:25,
    ':hover': {
        color: 'rgb(107,105,105)',
    },
    marginLeft:'10px',
    marginRight: '20px'
}

export default function PageSelector({page, setPage}) {

    return <Stack width={'100%'}>
        <Stack className="constructor-upper-panel">
            <Stack style={{cursor:'pointer'}}  sx={page==='messages'?activeButtonStyle:inactiveButtonStyle} onClick={()=>setPage('messages')}>Сообщения</Stack>
            <Stack style={{cursor:'pointer'}} sx={page==='buttons'?activeButtonStyle:inactiveButtonStyle} onClick={()=>setPage('buttons')}>Кнопки</Stack>
            <Stack style={{cursor:'pointer'}} sx={page==='reload'?activeButtonStyle:inactiveButtonStyle} onClick={()=>setPage('reload')}>Перезагрузка</Stack>
        </Stack>
    </Stack>
}
