import React, {useState, useEffect} from 'react'
import { useStoreContext } from "../store/store";


const WrapperAdaptive = ({children}) => {
    const {state} = useStoreContext();
    const [typeDevice, setTypeDevice] = useState(
        window.innerWidth <= 888 ? 'mobile' : 'pc'
    );

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 888) {
                setTypeDevice("mobile");
            } else {
                setTypeDevice("pc");
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    if (typeDevice == 'mobile') { 
        if (state.openMenu == true) {
            return (
                <div style={{width:'calc(100% - 100px)'}}>
                    {children}
                </div>
            )
        } else {
            return (
                <div style={{width:'100%'}}>
                    {children}
                </div>
            )
        }
    } else { 
        if (state.openMenuPC == true) {
            return (
                <div  style={{width:'calc(100% - 190px)'}}>
                    {children}
                </div>
            )
        } else {
            return (
                <div  style={{width:'calc(100% - 130px)'}}>
                    {children}
                </div>
            )
        }
    }

}

export default WrapperAdaptive