import {Stack} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {config} from "../../../config";
import getCookie from "../../../functions/getCookie";
import {useHistory} from "react-router-dom";


const iconStyle = {
    '&:hover': {
        color: 'rgb(107,105,105)',
    },
}



export default function CategoryAction(categories, flag, setFlag, setId, setOpen, setName, setDeleteOpen){
    const history = useHistory();
    const showHandler = (id, flag, setFlag) => {
        fetch(`${config.host}/lilshop/categories/showOne`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'id': id
            })
        }).then((response) => {
            if (response.status === 200) {
                setFlag(flag+1)
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/categories"})
            }
        })
    }

    const hideHandler = (id, flag, setFlag) => {
        fetch(`${config.host}/lilshop/categories/hideOne`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'id': id
            })
        }).then((response) => {
            if (response.status === 200) {
                setFlag(flag+1)
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/categories"})
            }
        })
    }

    return categories.map((item, i) => (
        <Stack key={i}
               direction={"row"} justifyContent={'space-evenly'}>
            <Stack>{item.active ? <VisibilityIcon onClick={()=>hideHandler(item.id, flag, setFlag)} sx={iconStyle}/> :
                <VisibilityOffIcon sx={iconStyle} onClick={()=>showHandler(item.id, flag, setFlag)} />}</Stack>
            <Stack onClick={() => {
                setId(item.id)
                setOpen(true);
            }}><EditIcon sx={iconStyle} /></Stack>
            <Stack><DeleteIcon sx={iconStyle} onClick={()=>{
                setName(item.name)
                setId(item.id)
                setDeleteOpen(true)}} /></Stack>
        </Stack>
    ))
}
