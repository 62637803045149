import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export default function MyDatePicker({ dateFilter,type }) {
  const [startDate, setStartDate] = useState(null);
  const datepickerRef = useRef(null);

  const openDatePicker = () => {
    if (datepickerRef && datepickerRef.current) {
      datepickerRef.current.setFocus(true);
    }
  };

  const handleDateChange = (date) => {
    date.setHours(12);
    const formattedDate = date ? date.toISOString().split('T')[0] + ' 00:00:00' : null;
    setStartDate(date);
    if (type == 'start') {
      dateFilter.set({...dateFilter.get,start: formattedDate})
    } 
    if (type == 'end') {
      dateFilter.set({...dateFilter.get,end: formattedDate})
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <DatePicker
        ref={datepickerRef}
        selected={startDate}
        onChange={handleDateChange}
        showPopperArrow={false}
        popperPlacement="auto"
        popperModifiers={{
          flip: {
            behavior: ['bottom'],
          },
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
        }}
        onClickOutside={() => setStartDate(null)}
        onFocus={() => setStartDate(null)}
        calendarClassName="custom-calendar"
      />
      <div style={{ marginLeft: '5px', marginTop: '4px' }} onClick={openDatePicker}>
        <CalendarTodayIcon style={{ color: '#2c8cd5', fontSize: '19px' }} />
      </div>
    </div>
  );
}
