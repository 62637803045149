import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getDataFromJsonList } from "../../functions/getDataFromJsonList";
import { config } from "../../config";
import ModalCreateEdit from "./Goods/ModalCreateEdit";
import Button from "@mui/material/Button";
import Table from "../Table/Table";
import ModalAddMaterial from "./Goods/ModalAddMaterial";
import ModalDelete from "./Goods/ModalDelete";
import ModalUnloadingMaterial from "./Goods/ModalUnloadingMaterial";
import GoodsAction from "./Goods/GoodsAction";
import GoodsFilter from "./Goods/GoodsFilter";
import getCookie from "../../functions/getCookie";
import { useHistory } from "react-router-dom";
import WrapperAdaptive from "../WrapperAdaptive";



const categoryNameHandler = (name) => {
    if (name) {
        return name
    } else return '--'
}

const priceHandler = (price) => {
    return price + " RUB"
}

const visionHandler = (active) => {
    if (active) {
        return 'Видна'
    }
    return 'Скрыта'
}
export default function Goods() {
    const [goods, setGoods] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(-1)
    const [open, setOpen] = useState(false)
    const [id, setId] = useState(0)
    const [name, setName] = useState('')
    const [flag, setFlag] = useState(0)
    const [addMaterialOpen, setAddMaterialOpen] = useState(false)
    const [unloadingMaterialOpen, setUnloadingMaterialOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const history = useHistory();
    const [sorts, setSorts] = useState([
        { title: 'Название', name: "name", type: null },
        { title: 'Категория', name: "category", type: null },
        { title: 'Цена', name: "price", type: null },
        { title: 'Колл-во материалов', name: "material_count", type: null }
    ])



    const tableSetting = [
        [
            {
                'title': 'Название',
                'width': '30%',
                'data': getDataFromJsonList('name', goods),
                'styled': false,
                'id': getDataFromJsonList('id', goods),
                'position': getDataFromJsonList('position', goods)
            },
            {
                'title': 'Категория',
                'width': '20%',
                'data': getDataFromJsonList('category', goods, categoryNameHandler),
                'styled': false,
            },
            {
                'title': 'Цена',
                'width': '10%',
                'data': getDataFromJsonList('price', goods, priceHandler),
                'styled': false,
            },
            {
                'title': 'Колл-во материалов',
                'width': '20%',
                'data': getDataFromJsonList('material_count', goods),
                'styled': false,
            },
            {
                'title': 'Видимость',
                'width': '10%',
                'data': getDataFromJsonList('active', goods, visionHandler),
                'styled': false,
            },
            {
                'title': 'Действия',
                'width': '10%',
                'data': GoodsAction(goods, flag, setFlag, setId, setOpen, setName, setDeleteOpen, setAddMaterialOpen, setUnloadingMaterialOpen),
                'styled': true,
            },
        ],
        {
            'page': {
                'get': page,
                'set': setPage
            },
            'limit': {
                'get': limit,
                'set': setLimit
            },
            'pages': pages,
            'search': {
                'get': search,
                'set': setSearch
            },
            'filter': {
                'ifFiltered': true,
                'get': <GoodsFilter categories={categories} category={category} setCategory={setCategory} />
            },
            'sort': {
                'get': sorts,
                'set': setSorts
            }
        }
    ]


    useLayoutEffect(() => {
        fetch(`${config.host}/lilshop/goods/getCategories`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setCategories(data)
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/goods" })
            }
        })

    }, [])

    useEffect(() => {
        const sort = sorts.find((sortItem) => sortItem.type != null)
        fetch(`${config.host}/lilshop/goods/getSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'limit': limit,
                'page': page,
                'search': search,
                'category': category,
                'sort': sort ? sort.name : 'position',
                'sort_type': sort ? sort.type : 'asc'
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setGoods(data.data)
                    if (pages !== data.pages) {
                        setPages(data.pages)
                    }
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/goods" })
            }
        })


    }, [limit, page, search, category, flag, sorts])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [pages])

    return <Stack direction={'row'}>
        <Menu active={'Goods'} />
        <WrapperAdaptive>
            <Stack direction={'column'} width={'100%'}>
                <Header />
                <ModalDelete open={deleteOpen} setOpen={setDeleteOpen} name={name} id={id} flag={flag} setFlag={setFlag} />
                <ModalCreateEdit open={open} setOpen={setOpen} categories={categories} id={id} flag={flag} setFlag={setFlag} />
                <ModalAddMaterial open={addMaterialOpen} setOpen={setAddMaterialOpen} name={name} id={id} flag={flag} setFlag={setFlag} />
                <ModalUnloadingMaterial open={unloadingMaterialOpen} setOpen={setUnloadingMaterialOpen} name={name} id={id} flag={flag} setFlag={setFlag} />
                <Stack className="main-style-page">
                    <Stack className="inner-in-main-page">
                        <div className="upper-panel-in-inner">
                            <div className="about-page">Товары</div>
                            <button className="btn-add" onClick={() => {
                                setId(0)
                                setOpen(true);
                            }}>Добавить товар</button>
                        </div>
                        <div className="split"></div>
                        <Table setting={tableSetting} section={"Goods"} />
                    </Stack>
                </Stack>
            </Stack>
        </WrapperAdaptive>

    </Stack>
}
