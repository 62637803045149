import React, { useState } from "react";
import {Stack} from "@mui/material";
import { useLocation } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { useStoreContext } from "../store/store";
import CloseIcon from '@mui/icons-material/Close';
const headerStyle = {
    backgroundColor:'rgb(255,255,255)',
    height:'9%',
    minHeight:'70px',
    maxHeight:'80px',
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    flexDirection: 'row'
}

const headerTextStyle= {
    color:'black',
    fontSize:'2em',
    marginLeft:10,
}

const exitBtn = {
    backgroundColor: '#2c8cd5',
    color:'white',
    fontWeight:'bold',
    borderRadius:'100%',
    padding:'8px 8px',
    marginRight: '15px',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: '#3c5cd10',
    },
}


export default function Header() {

    const {state,dispatch} = useStoreContext();

    const exit = () => {
        localStorage.setItem('token','');
        window.location.reload();
    }

    return <div style={headerStyle}>
         <div className="burger" onClick={()=>dispatch({ type: 'SET_MENU', payload: !state.openMenu })}>
            { 
                state.openMenu ?
                <CloseIcon fontSize="large" style={{color:"#2c8cd5"}}/>
                :
                <MenuIcon fontSize="large" style={{color:"#2c8cd5"}}/>
            } 
         </div>
         <div style={headerTextStyle} className="header_title" >LIL Shop Panel</div>
         <div style={exitBtn} onClick={exit}><LogoutIcon/></div>
    </div>
}














