import { FormControl, MenuItem, Select } from "@mui/material";

export default function GoodsFilter({ categories, category, setCategory }) {
    return <FormControl>
        <Select sx={{ height: 35, fontSize: 14, minWidth: 150, marginRight: 2 }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
        >
            <MenuItem value={-1} key={-1}>
                Все
            </MenuItem>
            {categories.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                    {item.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}
