import Button from "@mui/material/Button";
import React from "react";
import { Stack } from "@mui/material";

const buttonStyle = {
    border: 'solid 1px #d1d1d1',
    height: 30,
    color: "#797979",
    minWidth: 30,
    borderRadius: 0,
    ':hover': {
        color: 'rgb(107,105,105)',
    }
}

const threeDote = {
    height: 30,
    minWidth: 30,
    border: 'solid 1px #d1d1d1',
    borderRadius: 0,
    color: "#797979",
}

const activeButtonStyle = {
    height: 30,
    color: 'white',
    minWidth: 30,
    backgroundColor: '#2c8cd5',
    borderRadius: 0,
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
}


export default function TablePagesLine({ pages, page, setPage }) {
    let result = [];
    result.push(<Button disabled={page === 1} sx={buttonStyle} style={{ textTransform: 'capitalize', borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }} onClick={() => setPage(page - 1)}>
        Назад
    </Button>)
    if (pages <= 10) {
        for (let i = 1; i <= pages; i++) {
            result.push(<Button sx={i === page ? activeButtonStyle : buttonStyle} onClick={() => setPage(i)}>
                {i}
            </Button>)
        }
    } else {
        for (let i = 1; i <= pages; i++) {
            if (i === 10) {
                if (page !== 10) {
                    result.push(<Button style={threeDote} disabled>
                        ...
                    </Button>)
                }
                if (page > 9 && page !== pages) {
                    result.push(<Button>
                        {page}
                    </Button>)
                    if (page !== pages - 1) {
                        result.push(<Button style={threeDote} disabled >
                            ...
                        </Button>)
                    }
                }
                i = pages - 1;
            } else {
                result.push(<Button sx={i === page ? activeButtonStyle : buttonStyle} onClick={() => setPage(i)}>
                    {i}
                </Button>)
            }

        }
    }
    result.push(<Button disabled={page === pages} sx={buttonStyle} style={{ textTransform: 'capitalize', borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }} onClick={() => setPage(page + 1)}>
        Вперед
    </Button>)
    return result.map((item, i) => (
        <Stack key={i}>{item}</Stack>))
}
