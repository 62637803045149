import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { config } from "../../config";
import { useHistory } from "react-router-dom";

// const response = {
//     replenishmentDay:9999,
//     replenishmentMonth:99999,
//     replenishmentAll:999999,
//     profitDay:9999,
//     profitMonth:99999,
//     profitAll:999999,
//     saleDay:9999,
//     saleMonth:99999,
//     saleAll:999999,
//     activeUser:20000,
//     inactiveUser:4584,
//     commonBalance:999999
// }

const analyticsStyle = {
    minHeight: window.innerHeight,
    borderBottom: 0,
    height: '100%',
    backgroundColor: 'rgb(243, 243, 243)',
    alignItems: 'start',
    padding: 20
}

const analyticsTextStyle = {
    margin: '10px',

    width: '33%',
    padding: "35px",
    borderRadius: '30px',
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 7%)",
    backgroundColor: 'white',

    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
}

const leftText = {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
}

const rightText = {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
}

const textInfoTerm = {
    fontSize: '.7rem',
    color: 'rgb(138, 138, 138)',
    fontWeight: 'bold',
    marginBottom: '8px'
}

const analyticsBlockStyle = {
    marginLeft: '1%',
    display: 'flex',
    width: '98%',
}

const textMoney = {
    fontSize: '1.25rem',
    fontWeight: '600',
    color: 'rgb(94, 94, 94)'
}

export default function Analytics() {
    const [data, setData] = useState({
        replenishment_day: 9999,
        replenishment_month: 99999,
        replenishment_all: 9999999,
        profit_day: 9999,
        profit_month: 99999,
        profit_all: 999999,
        sales_day: 9999,
        sales_month: 99999,
        sales_all: 999999,
        users: 20000,
        inactive_users: 4584,
        balance_all: 999999
    })
    const history = useHistory();

    useEffect(() => {
        fetch(`${config.host}/lilshop/analytic/get`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setData(data)
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/analytic" })
            }
        })
    }, [])

    useState()

    return <Stack direction={'row'}>
        <Menu active={'Analytics'} />
        <Stack direction={'column'} width={'100%'}>
            <Header />
            <Stack style={analyticsStyle}>
                <Stack style={analyticsBlockStyle}>
                    <Stack direction={'row'} className="analitics_rows">
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПОПОЛНЕНО СЕГОДНЯ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.replenishment_day).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПОПОЛНЕНО ЗА НЕДЕЛЮ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.replenishment_week).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПОПОЛНЕНО ЗА МЕСЯЦ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.replenishment_month).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПОПОЛНЕНО ЗА ВСЕ ВРЕМЯ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.replenishment_all).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ЗАРАБОТАНО СЕГОДНЯ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.profit_day).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ЗАРАБОТАНО ЗА НЕДЕЛЮ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.profit_week).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ЗАРАБОТАНО ЗА МЕСЯЦ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.profit_month).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ЗАРАБОТАНО ЗА ВСЕ ВРЕМЯ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.profit_all).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПРОДАЖ СЕГОДНЯ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.sales_day).toLocaleString()}</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПРОДАЖ ЗА НЕДЕЛЮ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.sales_week).toLocaleString()}</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПРОДАЖ ЗА МЕСЯЦ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.sales_month).toLocaleString()}</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПРОДАЖ ЗА ВСЕ ВРЕМЯ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.sales_all).toLocaleString()}</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}> ПОЛЬЗОВАТЕЛЕЙ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.users).toLocaleString()}</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-users"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ПОЛЬЗОВАТЕЛЕЙ ВЫШЕДШИХ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.inactive_users).toLocaleString()}</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-users"></i>
                            </Stack>
                        </Stack>
                        <Stack style={analyticsTextStyle} className="analitics_block">
                            <Stack style={leftText}>
                                <Stack style={textInfoTerm}>ОБЩИЙ БАЛАНС ПОЛЬЗОВАТЕЛЕЙ</Stack>
                                <Stack style={textMoney}>{parseFloat(data.balance_all).toLocaleString()} RUB</Stack>
                            </Stack>
                            <Stack style={rightText}>
                                <i className="fas fa-coins"></i>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>

            </Stack>
        </Stack>

    </Stack>
}
