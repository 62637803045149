import {Alert, Checkbox, FormControlLabel, Snackbar, Stack, TextField} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import {config} from "../../../config";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import getCookie from "../../../functions/getCookie";


const textFieldStyle = {
    width: '70%',
    marginTop: '10px'
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    width: '60%',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}

export default function CryptoBot() {
    const [link, setLink] = useState('')
    const [secretkey, setSecretkey] = useState('')
    const [active, setActive] = useState(true)
    const [BTC, setBTC] = useState(true)
    const [TON, setTON] = useState(true)
    const [BNB, setBNB] = useState(true)
    const [USDT, setUSDT] = useState(true)
    const [open, setOpen] = useState(false)
    const history = useHistory();

    useEffect(() => {

        fetch(`${config.host}/lilshop/payments/getCryptoBot`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setLink(data.link)
                    setSecretkey(data.secret_key)
                    setActive(data.active)
                    setBTC(data.BTC)
                    setTON(data.TON)
                    setBNB(data.BNB)
                    setUSDT(data.USDT)
                })

            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/payments"})
            }
        })
    }, [])

    const saveHandler = () => {
        fetch(`${config.host}/lilshop/payments/updateCryptoBot`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'link': link,
                'secretkey': secretkey,
                'active': active,
                'BTC': BTC,
                'TON': TON,
                'BNB': BNB,
                'USDT': USDT,
            })
        }).then((response) => {
            if (response.status === 200) {
                setOpen(true)
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/payments"})
            }
        })
    }
    return <Stack className="block-pay-card"
                 >
        <Stack alignItems={'center'}>
            <Stack style={{width: '98%', marginLeft: '1%'}} direction={'row'} justifyContent={'space-evenly'}
                   alignItems={'center'}>
                <Stack fontSize={20}>
                    CryptoBot
                </Stack>
                <Stack>
                    <FormControlLabel width={'70%'}
                                      control={<Checkbox checked={active} onChange={() => setActive(!active)}/>}
                                      label="Включить"/>
                </Stack>
            </Stack>
            <TextField size="small" value={link} sx={textFieldStyle} label={'Ссылка'}
                       onChange={(e) => setLink(e.target.value)}/>
            <TextField size="small" value={secretkey} sx={textFieldStyle} label={'API token'}
                       onChange={(e) => setSecretkey(e.target.value)}/>
            <FormControlLabel sx={textFieldStyle}
                              control={<Checkbox checked={BTC} onChange={() => setBTC(!BTC)}/>}
                              label="BTC"/>
            <FormControlLabel sx={textFieldStyle}
                              control={<Checkbox checked={TON} onChange={() => setTON(!TON)}/>}
                              label="TON"/>
            <FormControlLabel sx={textFieldStyle}
                              control={<Checkbox checked={BNB} onChange={() => setBNB(!BNB)}/>}
                              label="BNB"/>
            <FormControlLabel sx={textFieldStyle}
                              control={<Checkbox checked={USDT} onChange={() => setUSDT(!USDT)}/>}
                              label="USDT"/>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity="success">
                    Сохранено!
                </Alert>
            </Snackbar>
            <Button sx={saveButtonStyle} className="save-constructor" onClick={saveHandler}>Сохранить</Button>
        </Stack>
    </Stack>
}
