const getDataFromJsonList = (name, data, handler = null) => {
    let result = [];
    for (let i = 0; i < data.length; i++) {
        if (handler) {
            if (name !== '') {
                result.push(handler(data[i][name]))
            }
            else {
                result.push(handler(data[i]))
            }

        }
        else {
            result.push(data[i][name])
        }
    }
    return result
}

module.exports = { getDataFromJsonList }
