import {Stack} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';


const iconStyle = {
    '&:hover': {
        color: 'rgb(107,105,105)',
    },
}

export default function UserAction(users,setBanId, setId, setName, setOpen) {
    return users.map((item, i) => (
        <Stack style={{height: 30}} key={i}
               direction={"row"} justifyContent={'space-evenly'}>
            <Stack>{item.is_ban ? <LockIcon sx={iconStyle} onClick={() => {
                setBanId(item.id)
            }}/> : <LockOpenIcon sx={iconStyle} onClick={() => {
                setBanId(item.id)
            }}/>}</Stack>
            <Stack><AccountBalanceWalletIcon sx={iconStyle} onClick={() => {
                setOpen(true)
                setName(item.username)
                setId(item.id)
            }}/></Stack>
        </Stack>
    ))
}
