import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getDataFromJsonList } from "../../functions/getDataFromJsonList";
import { config } from "../../config";
import Table from "../Table/Table";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import UserAction from "./Users/UserAction";
import { useHistory } from "react-router-dom";
import getCookie from "../../functions/getCookie";
import ModalBalance from "./Users/ModalBalance";
import WrapperAdaptive from "../WrapperAdaptive";



const categoriesBlockStyle = {
    marginLeft: '1%',
    display: 'flex',
    backgroundColor: 'white',
    width: '98%',
}


const iconStyle = {
    '&:hover': {
        // backgroundColor: 'rgba(206,204,206,0.75)',
        color: 'rgb(107,105,105)',
    },
}


const priceHandler = (price) => {
    return price + " RUB"
}

const banHandler = (isBan) => {
    if (isBan) {
        return "Заблокирован"
    }
    else {
        return "Разблокирован"
    }
}

const countHandler = (user) => {
    return user.order_count + '/' + user.material_count
}


export default function Orders() {
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [banId, setBanId] = useState(0)
    const [flag, setFlag] = useState(0)
    const history = useHistory();
    const [open, setOpen] = useState(false)
    const [name, setName] = useState(false)
    const [id, setId] = useState(0)
    const [sorts, setSorts] = useState([
        { title: null, type: null }
    ])

    const tableSetting = [
        [
            {
                'title': 'ID',
                'width': '20%',
                'data': getDataFromJsonList('telegram_id', users),
                'styled': false,
                'id': getDataFromJsonList('id', users),
            },
            {
                'title': 'Имя',
                'width': '30%',
                'data': getDataFromJsonList('name', users),
                'styled': false,
            },
            {
                'title': 'Баланс',
                'width': '20%',
                'data': getDataFromJsonList('balance', users, priceHandler),
                'styled': false,
            },
            {
                'title': 'Cумма покупок',
                'width': '20%',
                'data': getDataFromJsonList('orders_sum', users, priceHandler),
                'styled': false,
            },
            {
                'title': 'Колл-во покупок/товаров',
                'width': '30%',
                'data': getDataFromJsonList('', users, countHandler),
                'styled': false,
            },
            {
                'title': 'Дата',
                'width': '30%',
                'data': getDataFromJsonList('registration_date', users),
                'styled': false,
            },
            {
                'title': 'Статус',
                'width': '20%',
                'data': getDataFromJsonList('is_ban', users, banHandler),
                'styled': false,
            },
            {
                'title': 'Действия',
                'width': '10%',
                'data': UserAction(users, setBanId, setId, setName, setOpen),
                'styled': true,
            },
        ],
        {
            'page': {
                'get': page,
                'set': setPage
            },
            'limit': {
                'get': limit,
                'set': setLimit
            },
            'pages': pages,
            'search': {
                'get': search,
                'set': setSearch
            },
            'filter': {
                'ifFiltered': false
            },
            'sort': {
                'get': sorts,
                'set': setSorts
            }
        }
    ]


    useEffect(() => {
        const sort = sorts.find((sortItem) => sortItem.type != null)

        fetch(`${config.host}/lilshop/users/getSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'limit': limit,
                'page': page,
                'search': search,
                'sort': sort ? sort.title : null,
                'sort_type': sort ? sort.type : null
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setUsers(data.data)
                    if (pages !== data.pages) {
                        setPages(data.pages)
                    }
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/users" })
            }
        })

    }, [limit, page, search, flag, sorts])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [pages])

    useEffect(() => {
        if (banId !== 0) {
            fetch(`${config.host}/lilshop/users/setBan`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': banId
                }),
            }).then((response) => {
                if (response.status === 200) {
                    setFlag(flag + 1)
                    setBanId(0)
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/users" })
                }
                else if (response.status === 409) {

                }
            })
        }

    }, [banId])

    return <Stack direction={'row'}>
        <Menu active={'Users'} />
        <WrapperAdaptive>
            <Stack direction={'column'} width={'100%'}>
                <Header />
                <Stack className="main-style-page">
                    <ModalBalance open={open} setOpen={setOpen} id={id} setId={setId} name={name} flag={flag} setFlag={setFlag} />
                    <Stack className="inner-in-main-page">
                        <div className="upper-panel-in-inner">
                            <Stack className="about-page">Пользователи</Stack>
                        </div>
                        <div className="split"></div>
                        <Table setting={tableSetting} />
                    </Stack>
                </Stack>
            </Stack>
        </WrapperAdaptive>

    </Stack>
}
