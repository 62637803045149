import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getDataFromJsonList } from "../../functions/getDataFromJsonList";
import { config } from "../../config";
import Table from "../Table/Table";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OrderAction from "./Orders/OrderAction";
import getCookie from "../../functions/getCookie";
import { useHistory } from "react-router-dom";
import WrapperAdaptive from "../WrapperAdaptive";


const categoriesBlockStyle = {
    marginLeft: '1%',
    display: 'flex',
    backgroundColor: 'white',
    width: '98%',
}


const iconStyle = {
    '&:hover': {
        // backgroundColor: 'rgba(206,204,206,0.75)',
        color: 'rgb(107,105,105)',
    },
}


const priceHandler = (price) => {
    return price + " RUB"
}


export default function Orders() {
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [id, setId] = useState(0)
    const [name, setName] = useState('')
    const history = useHistory();
    const [sorts, setSorts] = useState([
        { title: 'Заказ', name: "id", type: null },
        { title: 'Товар', name: "name", type: null },
        { title: 'Покупатель', name: "buyer", type: null },
        { title: 'Дата', name: "datetime", type: null },
        { title: 'Колл-во', name: "count", type: null },
        { title: 'Cумма', name: "sum", type: null },
    ])

    
    const tableSetting = [
        [
            {
                'title': 'Заказ',
                'width': '10%',
                'data': getDataFromJsonList('id', orders),
                'styled': false,
                'id': getDataFromJsonList('id', orders),
            },
            {
                'title': 'Товар',
                'width': '50%',
                'data': getDataFromJsonList('name', orders),
                'styled': false,
            },
            {
                'title': 'Покупатель',
                'width': '30%',
                'data': getDataFromJsonList('buyer', orders),
                'styled': false,
            },
            {
                'title': 'Имя',
                'width': '30%',
                'data': getDataFromJsonList('buyer_name', orders),
                'styled': false,
            },
            {
                'title': 'Дата',
                'width': '30%',
                'data': getDataFromJsonList('datetime', orders),
                'styled': false,
            },
            {
                'title': 'Колл-во',
                'width': '15%',
                'data': getDataFromJsonList('count', orders),
                'styled': false,
            },
            {
                'title': 'Cумма',
                'width': '20%',
                'data': getDataFromJsonList('sum', orders, priceHandler),
                'styled': false,
            },
            {
                'title': 'Действия',
                'width': '5%',
                'data': OrderAction(orders, setId, setName),
                'styled': true,
            },
        ],
        {
            'page': {
                'get': page,
                'set': setPage
            },
            'limit': {
                'get': limit,
                'set': setLimit
            },
            'pages': pages,
            'search': {
                'get': search,
                'set': setSearch
            },
            'filter': {
                'ifFiltered': false
            },
            'sort': {
                'get': sorts,
                'set': setSorts
            }
        }
    ]

    useEffect(() => {
        const sort = sorts.find((sortItem) => sortItem.type != null)

        fetch(`${config.host}/lilshop/orders/getSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'limit': limit,
                'page': page,
                'search': search,
                'sort': sort ? sort.name : 'datetime',
                'sort_type': sort ? sort.type : 'desc'
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setOrders(data.data)
                    if (pages !== data.pages) {
                        setPages(data.pages)
                    }
                })
            }
        })

    }, [limit, page, search, sorts])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [pages])

    useEffect(() => {

        if (id !== 0) {
            fetch(`${config.host}/lilshop/orders/getMaterial`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id
                }),
            }).then((response) => {
                if (response.status === 200) {
                    response.text().then((data) => {
                        const element = document.createElement("a");
                        const file = new Blob([data], { type: 'text/plain' });
                        element.href = URL.createObjectURL(file);
                        element.download = `${name}.txt`;
                        document.body.appendChild(element); // Required for this to work in FireFox
                        element.click();
                        setId(0)
                        setName('')
                    })
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/orders" })
                }
            })
        }

    }, [id])

    return <Stack direction={'row'}>
        <Menu active={'Orders'} />
        <WrapperAdaptive>
            <Stack direction={'column'} width={'100%'}>
                <Header />
                <Stack className="main-style-page">

                    <Stack className="inner-in-main-page">
                        <div className="upper-panel-in-inner">
                            <div className="about-page">Заказы</div>
                        </div>
                        <div className="split"></div>
                        <Table setting={tableSetting} />
                    </Stack>

                </Stack>
            </Stack>
        </WrapperAdaptive>
    </Stack>
}
