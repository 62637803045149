import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import MessagesCard from "./Constructor/MessageCard";
import QiwiP2P from "./Payments/QiwiP2P";
import Qiwi from "./Payments/Qiwi";
import YooMoney from "./Payments/YooMoney";
import CryptoBot from "./Payments/CryptoBot";
import PageSelector from "./Constructor/PageSelector";
import Messages from "./Constructor/Messages";
import Buttons from "./Constructor/Buttons";
import Payok from "./Payments/Payok";
import WrapperAdaptive from "../WrapperAdaptive";

export default function Payments() {
    return <Stack direction={'row'}>
        <Menu active={'Payments'} />
        <WrapperAdaptive>
            <Stack direction={'column'} width={'100%'}>
                <Header />
                <Stack className="main-style-page">
                    <Stack className="inner-in-main-page">
                        <Stack style={{ fontSize: 20, alignItems:'start', marginBottom: 20, marginLeft: 20, width:'100%' }}>Оплата</Stack>
                        <div className="split" style={{marginBottom:'20px'}}></div>
                            <div style={{maxWidth:'100%', display:'flex', justifyContent:'center'}}>
                                <div className="constructor-card-pay">
                                    <QiwiP2P />
                                    <Qiwi />
                                    <YooMoney />
                                    <CryptoBot />
                                    <Payok />
                                </div>
                            </div>  
                    </Stack>
                </Stack>
            </Stack>
        </WrapperAdaptive>
    </Stack>
}
