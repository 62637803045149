import React, { createContext, useReducer, useContext } from "react";

const initialState = {
    openMenu: false,
    openMenuPC: false,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case "SET_MENU":
            return { ...state, openMenu: action.payload };
        case "SET_MENU_PC":
            return { ...state, openMenuPC: action.payload };
        default:
            return state;
    }
};

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            {children}
        </StoreContext.Provider>
    );
};

export const useStoreContext = () => useContext(StoreContext);
