import { Stack } from "@mui/material";
import TableCell from "./TableCell";

export default function TableTitle({ data, sort }) {


    const goSort = (title) => {
        const newState = [...sort.get]
        for (const itemSort of newState) {
            if (itemSort.title === title) {
                if (itemSort.type == 'asc') {
                    itemSort.type = 'desc'
                } else {
                    itemSort.type = 'asc'
                }
            } else {
                itemSort.type = null
            }
        }
        sort.set(newState)
    }

    return <Stack direction={'row'} style={{ borderBottom: 'solid 2px #e7e7e7' }}>
        {data.map((item, i) => {
            for (const sortItem of sort.get) {
                if (sortItem.title === item.title) {
                    return <TableCell key={i} data={item['title']} num={1} width={item.width} one={i === 0} sort_item={true} goSort={goSort} />
                }
            }
            return <TableCell key={i} data={item['title']} num={1} width={item.width} one={i === 0} sort_item={false} goSort={goSort} />
        }
        )}
    </Stack>
}
