import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Checkbox, FormControlLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import {config, quillModules} from "../../../config";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import 'dayjs/locale/ru';
import ModalDelete from "../Constructor/ModalDelete";
import getCookie from "../../../functions/getCookie";
import {useHistory} from "react-router-dom";


Quill.register('modules/imageResize', ImageResize)

const modalStyle = {
    position: 'absolute',
    width: '60%',
    maxWidth: '500px',
    borderRadius: '15px',
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '20px',
    maskImage: 'linear-gradient(to right, transparent, 0px, white calc(100% - 0px), transparent)'
}

const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px'
}

const selectStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    height: '40px',
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '25px',
    marginBottom: '10px',
    height: '40px',
}

const quillStyle ={
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '40px'
}

const imageStyle ={
    maxWidth: '60%',
    maxHeight: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
}

export default function ModalCreateEdit({open, setOpen, id, flag, setFlag}) {
    const [name, setName] = useState('')
    const [content, setContent] = useState('')
    const [image, setImage] = useState('')
    const [active, setActive] = useState(true)
    const [buttonName, setButtonName] = useState('')
    const [buttonLink, setButtonLink] = useState('')
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [fixed, setFixed] = useState(false)
    const history = useHistory();

    useEffect(()=>{
        if(id){
            fetch(`${config.host}/lilshop/buttons/getOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id,
                })
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setName(data.name)
                        setContent(data.text)
                        setImage(data.image||'')
                        setActive(data.active)
                        setButtonName(data.nameInline)
                        setButtonLink(data.linkInline)
                        setFixed(data.fixed)
                    })
                }
                else if(response.status === 401){
                    history.push('/lilshop/authentication', {from: "/lilshop/constructor"})
                }
            })
        }
        else {
            setName('')
            setActive(true)
            setContent('')
            setImage('')
            setButtonName('')
            setButtonLink('')
            setFixed(true)
        }
    }, [id])


    const handleSave = () => {
        if(id){
            fetch(`${config.host}/lilshop/buttons/updateOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id,
                    'name': name,
                    'text': content,
                    'active': active,
                    'image': image,
                    'nameInline':buttonName,
                    'linkInline':buttonLink
                })
            }).then((response) => {
                if (response.status === 200) {
                    setOpen(false)
                    setFlag(flag+1)
                }
                else if(response.status === 401){
                    history.push('/lilshop/authentication', {from: "/lilshop/constructor"})
                }
            })
        }
        else {
            fetch(`${config.host}/lilshop/buttons/createOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'name': name,
                    'text': content,
                    'image': image,
                    'active': active,
                    'nameInline':buttonName,
                    'linkInline':buttonLink
                })
            }).then((response) => {
                if (response.status === 200) {
                    setOpen(false)
                    setFlag(flag+1)
                }
                else if(response.status === 401){
                    history.push('/lilshop/authentication', {from: "/lilshop/constructor"})
                }
            })
        }
    }


    return <div>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                {/*{parents}*/}
                <ModalDelete open={deleteOpen} setOpen={setDeleteOpen} name={name} id={id} flag={flag} setFlag={setFlag} setParentOpen={setOpen}/>
                <Stack
                    style={{ marginBottom: "10px", fontSize: 25, backgroundColor: '#2c8cd5', padding: 10, color: 'white' }} alignItems={'center'}>{id ? 'Изменить кнопку' : 'Добавить кнопку'}</Stack>
                <Stack >
                    <Stack direction={'column'} sx={{width:'100%'}} justifyContent={'space-around'}>
                        <TextField value={name} size="small" sx={textFieldStyle} label={'Название'} onChange={(e)=>setName(e.target.value)}/>
                        <ReactQuill
                            style={quillStyle}
                            modules={quillModules}
                            theme="snow"
                            value={content}
                            onChange={setContent}
                        />
                        <TextField size="small" value={image} sx={textFieldStyle} label={'Изображение'} onChange={(e)=>setImage(e.target.value)}/>
                        <img style={imageStyle} src={image}/>
                        <Stack>
                            <FormControlLabel sx={textFieldStyle} control={<Checkbox  checked={active} onChange={()=>setActive(!active)}/>} label="Включить" />
                        </Stack>
                        {!fixed?<Button sx={{color:'red'}} onClick={()=>setDeleteOpen(true)}>Удалить</Button>:<Stack/>}
                    </Stack>
                    <Stack>
                        <TextField size="small" value={buttonName} sx={textFieldStyle} label={'Название кнопки'} onChange={(e)=>setButtonName(e.target.value)}/>
                        <TextField size="small" value={buttonLink} sx={textFieldStyle} label={'Ссылка'} onChange={(e)=>setButtonLink(e.target.value)}/>
                    </Stack>
                    <Button sx={saveButtonStyle} onClick={handleSave}>Сохранить</Button>
                </Stack>
            </Box>
        </Modal>
    </div>
}
