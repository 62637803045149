import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import { config, quillModules } from "../../../config";
import getCookie from "../../../functions/getCookie";
import { useHistory } from "react-router-dom";

Quill.register('modules/imageResize', ImageResize)

const modalStyle = {
    position: 'absolute',
    width: '60%',
    maxWidth: '500px',
    borderRadius: 4,
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '20px'
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 50,
    width: '30%',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}

export default function ModalDelete({ open, setOpen, id, name, flag, setFlag }) {
    const history = useHistory();

    const handleAccept = () => {
        fetch(`${config.host}/lilshop/mailings/deleteOne`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'id': id
            })
        }).then((response) => {
            if (response.status === 200) {
                setOpen(false)
                setFlag(flag + 1)
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/mailing" })
            }
        })
    }


    return <div>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Stack
                    style={{ marginBottom: 10, fontSize: 25, backgroundColor: '#2c8cd5', padding: 10, color: 'white' }} alignItems={'center'}>Удалить рассылку ?</Stack>
                <Stack style={{ fontSize: 19, margin: "15px 0px", textAlign: 'center' }}>{name}</Stack>
                <Stack flexDirection={'row'} justifyContent={'center'}>
                    <Button sx={saveButtonStyle} onClick={handleAccept}>Да</Button>
                    <Button sx={saveButtonStyle} onClick={() => setOpen(false)}>Нет</Button>
                </Stack>
            </Box>
        </Modal>
    </div>
}
