import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Alert, MenuItem, Select, Snackbar, Stack, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import { config, quillModules } from "../../../config";
import getCookie from "../../../functions/getCookie";
import { useHistory } from "react-router-dom";

Quill.register('modules/imageResize', ImageResize)

const modalStyle = {
    position: 'absolute',
    width: '60%',
    maxWidth: '500px',
    borderRadius: '15px',
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '20px',
    maskImage: 'linear-gradient(to right, transparent, 0px, white calc(100% - 0px), transparent)'
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 50,
    width: '30%',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}

const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px'
}

export default function ModalBalance({ open, setOpen, id, setId, name, flag, setFlag, refills }) {
    const history = useHistory();
    const [balance, setBalance] = useState(0)
    const [oldBalance, setOldBalance] = useState(0)
    const [error, setError] = useState(false)

    useEffect(() => {
        console.log(refills, id)
        if (refills) {
            if (id !== 0) {
                fetch(`${config.host}/lilshop/users/getBalance`, {
                    method: 'Post', // или 'PUT'
                    headers: {
                        'accept': 'text/plain',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'token': localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        'telegram_id' : Number(id)
                    })
                }).then((response) => {
                    if (response.status === 200) {
                        response.text().then((data) => {
                            setBalance(parseFloat(data))
                            setOldBalance(parseFloat(data))
                        })
                    } else if (response.status === 401) {
                        history.push('/lilshop/authentication', { from: "/lilshop/mailing" })
                    }
                })
            }
        } else {
            if (id !== 0) {
                fetch(`${config.host}/lilshop/users/getBalance`, {
                    method: 'Post', // или 'PUT'
                    headers: {
                        'accept': 'text/plain',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'token': localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        'id' : id
                    })
                }).then((response) => {
                    if (response.status === 200) {
                        response.text().then((data) => {
                            setBalance(parseFloat(data))
                            setOldBalance(parseFloat(data))
                        })
                    } else if (response.status === 401) {
                        history.push('/lilshop/authentication', { from: "/lilshop/mailing" })
                    }
                })
            }
        }

    }, [id])

    const handleAccept = () => {
        if (refills) {
            fetch(`${config.host}/lilshop/users/setBalance`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'telegram_id': Number(id),
                    'oldBalance': oldBalance,
                    'newBalance': balance
                })
            }).then((response) => {
                if (response.status === 200) {
                    setOpen(false)
                    setFlag(flag + 1)
                    setId(0)
                } else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/users" })
                }
                else if (response.status === 409) {
                    setError(true)
                }
            })
        } else {
            fetch(`${config.host}/lilshop/users/setBalance`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id,
                    'oldBalance': oldBalance,
                    'newBalance': balance
                })
            }).then((response) => {
                if (response.status === 200) {
                    setOpen(false)
                    setFlag(flag + 1)
                    setId(0)
                } else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/users" })
                }
                else if (response.status === 409) {
                    setError(true)
                }
            })
        }
    }


    return <div>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='modal-box'>
                <Stack
                    style={{ marginBottom: 10, fontSize: 25, backgroundColor: '#2c8cd5', padding: 10, color: 'white' }} alignItems={'center'}>Изменить баланс {name}?</Stack>
                <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                        <Alert onClose={() => setError(false)} severity="error">
                            Баланс изменился обновите старницу!
                        </Alert>
                    </Snackbar>
                    <TextField size="small" value={balance} sx={textFieldStyle} onChange={(e) => setBalance(e.target.value)} />
                    <Button sx={saveButtonStyle} onClick={handleAccept}>Сохранить</Button>
                </Stack>
            </Box>
        </Modal>
    </div>
}
