import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getDataFromJsonList } from "../../functions/getDataFromJsonList";
import { config } from "../../config";
import Table from "../Table/Table";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OrderAction from "./Orders/OrderAction";
import getCookie from "../../functions/getCookie";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import WrapperAdaptive from "../WrapperAdaptive";
import GoodsFilter from './Goods/GoodsFilter'




export default function Sells() {

    const [sells, setSells] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [dateFilter, setDateFilter] = useState({start:null, end:null})
    const [id, setId] = useState(0)
    const [name, setName] = useState('')
    const history = useHistory();
    const [sorts, setSorts] = useState([
        { title: 'Название', name: "", type: null },
        { title: 'Категория', name: "", type: null },
        { title: 'Колл-во', name: "", type: null },
        { title: 'Сумма', name: "", type: null },
    ])

    console.log(dateFilter)

    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(-1)

    useLayoutEffect(() => {
        fetch(`${config.host}/lilshop/goods/getCategories`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setCategories(data)
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/goods" })
            }
        })

    }, [])

    const tableSetting = [
        [
            {
                'title': 'ID',
                'width': '30%',
                'data': getDataFromJsonList('good', sells),
                'styled': false,
                'id': [1,2,3],
            },
            {
                'title': 'Название',
                'width': '30%',
                'data': getDataFromJsonList('name', sells),
                'styled': false,
            },
            {
                'title': 'Категория',
                'width': '20%',
                'data': getDataFromJsonList('category_name', sells),
                'styled': false,
            },
            {
                'title': 'Количество',
                'width': '20%',
                'data': getDataFromJsonList('count', sells),
                'styled': false,
            },
            {
                'title': 'Сумма',
                'width': '20%',
                'data': getDataFromJsonList('sum', sells),
                'styled': false,
            }
        ],
        {
            'page': {
                'get': page,
                'set': setPage
            },
            'limit': {
                'get': limit,
                'set': setLimit
            },
            'pages': pages,
            'search': {
                'get': search,
                'set': setSearch
            },
            'filter': {
                'ifFiltered': true,
                'get': <GoodsFilter categories={categories} category={category} setCategory={setCategory} />
            },
            'sort': {
                'get': sorts,
                'set': setSorts
            },
            'dateFilter': {
                'get': dateFilter,
                'set': setDateFilter
            }
        }
    ]

    useEffect(() => {
        const sort = sorts.find((sortItem) => sortItem.type != null)

        console.log('send',dateFilter.start, dateFilter.end)

        fetch(`${config.host}/lilshop/sells/getSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'limit': limit,
                'page': page,
                'search': search,
                'sort': sort ? sort.name : 'datetime',
                'sort_type': sort ? sort.type : 'desc',
                'category': category == -1 ? null : category,
                'start': dateFilter.start,
                'end': dateFilter.end
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setSells(data.data)
                    if (pages !== data.pages) {
                        setPages(data.pages)
                    }
                })
            }
        })

    }, [limit, page, search, sorts,category,dateFilter])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [pages])

    return (
    <Stack direction={'row'}>
        <Menu active={'Sells'} />
        <WrapperAdaptive>
            <Stack direction={'column'} style={{width:'100%'}} >
                <Header />
                <Stack className="main-style-page">
                    <Stack className="inner-in-main-page">
                        <div className="upper-panel-in-inner">
                            <div className="about-page">Продажи</div>
                        </div>
                        <div className="split"></div>
                        <Table setting={tableSetting} />
                    </Stack>

                </Stack>
            </Stack>
        </WrapperAdaptive>
    </Stack>
    )
}
