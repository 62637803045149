import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getDataFromJsonList } from "../../functions/getDataFromJsonList";
import { config } from "../../config";
import Table from "../Table/Table";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OrderAction from "./Orders/OrderAction";
import getCookie from "../../functions/getCookie";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WrapperAdaptive from "../WrapperAdaptive";
import ReffilsAction from "./Refills/RefillsAction";
import ModalBalance from "./Users/ModalBalance";



export default function Refills() {
    const [refills, setRefills] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [limit, setLimit] = useState(10)
    const [banId, setBanId] = useState(0)
    const [search, setSearch] = useState('')
    const [id, setId] = useState(0)
    const [name, setName] = useState('')
    const history = useHistory();
    const [flag, setFlag] = useState(0)

    const [open, setOpen] = useState(false)
    
    const [sorts, setSorts] = useState([
        { title: 'Сумма пополнения', name: "sum", type: null },
        { title: 'Способ оплаты', name: "method", type: null },
        { title: 'Дата', name: "date", type: null },
    ])

    const tableSetting = [
        [
            {
                'title': 'ID',
                'width': '5%',
                'data': getDataFromJsonList('id',refills),
                'styled': false,
                'id': [1,2,3],
            },
            {
                'title': 'Пользователь',
                'width': '20%',
                'data': getDataFromJsonList('user',refills),
                'styled': false,
            },
            {
                'title': 'Имя',
                'width': '20%',
                'data': getDataFromJsonList('user_name',refills),
                'styled': false,
            },
            {
                'title': 'Сумма пополнения',
                'width': '10%',
                'data': getDataFromJsonList('sum',refills),
                'styled': false,
            },
            {
                'title': 'Способ оплаты',
                'width': '20%',
                'data': getDataFromJsonList('method',refills),
                'styled': false,
            },
            {
                'title': 'Дата',
                'width': '20%',
                'data': getDataFromJsonList('datetime',refills),
                'styled': false,
            },
            {
                'title': 'Действия',
                'width': '5%',                
                'data': ReffilsAction(refills, setBanId ,setId, setName, setOpen),
                'styled': true,
            },
        ],
        {
            'page': {
                'get': page,
                'set': setPage
            },
            'limit': {
                'get': limit,
                'set': setLimit
            },
            'pages': pages,
            'search': {
                'get': search,
                'set': setSearch
            },
            'filter': {
                'ifFiltered': false
            },
            'sort': {
                'get': sorts,
                'set': setSorts
            }
        }
    ]

    useEffect(() => {
        const sort = sorts.find((sortItem) => sortItem.type != null)

        fetch(`${config.host}/lilshop/replenishments/getSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'limit': limit,
                'page': page,
                'search': search,
                'sort': sort ? sort.name : 'date',
                'orderType': sort ? sort.type : 'desc'
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setRefills(data.data)
                    if (pages !== data.pages) {
                        setPages(data.pages)
                    }
                })
            }
        })

    }, [limit, page, search,flag, sorts])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [pages])

    useEffect(() => {
        console.log(banId)
        console.log(flag)
        if (banId !== 0) {
            fetch(`${config.host}/lilshop/users/setBan`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'telegram_id': banId,
                }),
            }).then((response) => {
                if (response.status === 200) {
                    setFlag(flag + 1)
                    setBanId(0)
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/users" })
                }
                else if (response.status === 409) {

                }
            })
        }

    }, [banId])

    return <Stack direction={'row'}>
        <Menu active={'Refills'} />
        <WrapperAdaptive>
            <Stack direction={'column'} width={'100%'}>
                <Header />
                <Stack className="main-style-page">
                    <ModalBalance refills={true} open={open} setOpen={setOpen} id={id} setId={setId} name={name} flag={flag} setFlag={setFlag} />
                    <Stack className="inner-in-main-page">
                        <div className="upper-panel-in-inner">
                            <div className="about-page">Пополнения</div>
                        </div>
                        <div className="split"></div>
                        <Table setting={tableSetting} />
                    </Stack>

                </Stack>
            </Stack>
        </WrapperAdaptive>

    </Stack>
}
